import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../../context/AuthContext';
import './SessionsPage.css';
import Sidebar from '../../webAppComponents/Sidebar/Sidebar';
import FilterBy from '../../webAppComponents/FilterBy/FilterBy';
import { FaUser } from 'react-icons/fa';
import { LiaCheckDoubleSolid } from 'react-icons/lia';
import { LuClock } from 'react-icons/lu';
import CreateSessionPopup from '../../webAppPages/CreateSessionPopup/CreateSessionPopup';
import TrainingsPageChanger from '../../webAppComponents/TrainingsPageChanger/TrainingsPageChanger';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"

function SessionsPage() {
  const { t } = useTranslation(['sessions-page']);
  const [microcycles, setMicrocycles] = useState([]);
  const [selectedMicrocycle, setSelectedMicrocycle] = useState('');
  const { authTokens } = useContext(AuthContext);
  const [sessionData, setSessionData] = useState([]);
  const [createSession, setCreateSession] = useState(false);


  const headers = {
    Authorization: `Bearer ${authTokens.access}`,
  };

  useEffect(() => {
    if(selectedMicrocycle == '') return;

    axios
      .get(process.env.REACT_APP_BACKEND + `training/session/list?microcycle=${selectedMicrocycle}`, { headers })
      .then((response) => {
        setSessionData(response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [selectedMicrocycle]);

  const toggleStatus = async (item, index) => {
    const updatedStatus = !item.completed
    const updatedSessionData = [...sessionData];
    updatedSessionData[index].completed = updatedStatus;
    setSessionData(updatedSessionData);
    const currentTraining = item;

    try {
      // Make an API call to update the status in the backend
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND}training/session/update/${item.id}`,
        { completed: updatedStatus , currentTraining},
        { headers }
      );
    } catch (error) {
      console.error('Error occurred:', error.response ? error.response.data : error.message);
    }
  };

  const handleMicrocycleChange = (selectedOption) => {
    setSelectedMicrocycle(selectedOption);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND + `training/microcycle-list`, {
        headers,
      })
      .then((response) => {
        let microcycles_settle = [];
        for (let i = 0; i < response.data.length; i++) {
          microcycles_settle.push({
            label: `${response.data[i].start_date}-${response.data[i].until_date}`,
            value: response.data[i].id,
            id: response.data[i].id,
          });
        }
        setMicrocycles(microcycles_settle);
        if(microcycles_settle.length > 0)
          setSelectedMicrocycle(microcycles_settle[0].id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const getStatusElement = (item, index) => {
    if (item.completed === false) {
      return (
        <span className='ongoing-box' onClick={() => toggleStatus(item, index)}>
          <LuClock /> {t('On Going')}
        </span>
      );
    } else if (item.completed === true) {
      return (
        <span className='completed-box' onClick={() => toggleStatus(item, index)}>
          <LiaCheckDoubleSolid /> {t('Complete')}
        </span>
      );
    }
    return null;
  };

  const handleCreateSession = () => {
    setCreateSession(!createSession);
  }

  const placeBackgroundImages = () => {
    const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
    const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
    const bckLogo1Height = bckLogo1.offsetHeight;
    bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
    bckLogo2.style.top = `0%`;
    const bckLogo1Width = bckLogo1.offsetWidth;
    bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
    bckLogo2.style.left = `75%`;
  }

  useEffect(() => {
      placeBackgroundImages();
      window.addEventListener('resize', placeBackgroundImages);
      return () => {
          window.removeEventListener('resize', placeBackgroundImages);
      }
  }, [])

  return (
    <div className='webapp-sessions-page-scroll'>
      <Sidebar selectedOption='trainings' />

      <div className='webapp-sessions-page'>
        <div className="webapp-create-team-background-images">
            <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
            <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
        </div>
        <div className="webapp-trainings-page-subsections"  >
          <TrainingsPageChanger
            selectedPage={1}
            pages={[
              { label: t('Your Exercises'), path: '/webapp/trainings' },
              { label: t('Your Training Sessions'), path: '/webapp/sessions' },
            ]}
          />
        </div>
        <div className='webapp-sessions-page-header'>
          <h1>{t('Training Sessions')}</h1>
          <button onClick={handleCreateSession}>+ {t('New Session')}</button>
        </div>

        {microcycles.length !== 0 ? (<FilterBy options={microcycles} onChange={handleMicrocycleChange}/>) : null}

        <div className='webapp-sessions-page-table'>
          <div className='webapp-sessions-page-top-bar'>
            <div className='webapp-sessions-page-top-bar-items'>
              <span className='webapp-sessions-page-top-bar-items-10w'>{t('Session')}</span>
              <span className='webapp-sessions-page-top-bar-items-15w'>{t('Microcycle')}</span>
              <span className='webapp-sessions-page-top-bar-items-25w'>{t('Session Date')}</span>
              <span className='webapp-sessions-page-top-bar-items-25w'>{t('Quizzes')}</span>
            </div>
          </div>

          <div className='webapp-sessions-page-table-rows'>
            {sessionData.map((item, index) => (
              <div
                className={`webapp-sessions-page-table-row-items ${index < 2 ? 'centered' : 'left-aligned'
                  }`}
                key={item.id}
              >
                <div className='webapp-sessions-page-table-row-items-info'>
                  <span className='webapp-sessions-page-top-bar-items-10w'>
                    {item.session_number}
                  </span>
                  <span className='webapp-sessions-page-top-bar-items-15w'>
                    {item.microcycle.cycle_number}
                  </span>
                  <span className='webapp-sessions-page-top-bar-items-25w'>
                    {new Date(item.date).toLocaleDateString('pt-PT')}{getStatusElement(item, index)}
                  </span>
                  <span className='webapp-sessions-page-top-bar-items-30w webapp-session-page-quizzes'>
                    <div className='webapp-session-page-quizzes-values'>
                      <div>
                        {t('Pre-Training')}
                      </div>
                      <div className='value-icon'>
                        {item.preSessionAwnsered} <FaUser className='user-icon' />
                      </div>
                    </div>
                    <div className='webapp-session-page-quizzes-values'>
                      <div>
                        {t('Training Gym')}
                      </div>
                      <div className='value-icon'>
                        {item.ucGymAnswered}<FaUser className='user-icon' />
                      </div>
                    </div>
                    <div className='webapp-session-page-quizzes-values'>
                      <div>
                        {t('Training Field')}
                      </div>
                      <div className='value-icon'>
                        {item.ucTotalAnswered}
                        <FaUser className='user-icon' />
                      </div>
                    </div>
                  </span>
                </div>
                <Link to={`/webapp/SessionUC/`} state = {{"session": item.id, "training": item?.training?.id} }><button>{t('See')}</button></Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      {createSession ? (<div className="create-session-popup"><CreateSessionPopup handleReturn={handleCreateSession} /></div>) : null}
    </div>
  );
}

export default SessionsPage;
