import "./UCPage.css";

import { useState, useEffect, useContext } from "react";

import Sidebar from "../../webAppComponents/Sidebar/Sidebar";
import FilterBy from "../../webAppComponents/FilterBy/FilterBy";
import DashboardFilters from "../../webAppComponents/DashboardFilters/DashboardFilters";

import axios from "axios";
import AuthContext from "../../context/AuthContext";

import { useTranslation } from "react-i18next";
import GraphsUC from "../../webAppComponents/GraphsUC/GraphsUC";

import BckLogo from "../../webAppAssets/BackgroundLogo.svg"

const UCpage = () => {
  const { t } = useTranslation(["uc-page"]);

  const [page, setPage] = useState("total");
  const [selectedFilter, setSelectedFilter] = useState("0");

  const [microcycles, setMicrocycles] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [players, setPlayers] = useState([]);
  const [statistics, setStatistics] = useState([]);

  const [selectedDay, setSelectedDay] = useState(0);
  const [selectedMicrocycle, setSelectedMicrocycle] = useState(null);
  const [filters, SetFilters] = useState([]); // all the filters that should be available
  const [displayedFilters, setDisplayedFilters] = useState([]); // the filters to be displayed
  // filters selected by the user
  const [selectedFilters, setSelectedFilters] = useState({
    "MC" : [],
    "Position" : [],
    "Name":[]
  });

  const [numberDisplayedNames, setNumberDisplayedNames] = useState(0); // helps to manage the displayed filters
  const [numberDisplayedPos, setNumberDisplayedPos] = useState(0); 
  const [microcycleData, setMicrocycleData] = useState({});
  const [graphData,setGraphData] = useState({
      labels: [],
      graph1: {
        line: [], bar1: [], bar2: []
      },
      graph2: {
        line: [], bar1: [], bar2: []
      },
      graph3: {
        line: [], bar1: [],
      }
  });

  const { authTokens } = useContext(AuthContext);
  const headers = {
    Authorization: `Bearer ${authTokens.access}`,
  };

  const filterOptions = [
    { label: t("Days of the Week"), value: "0" },
    { label: t("Total Microcycle"), value: "1" },
  ];

  const handleMicrocycleChange = (value) => {
    // find the seleted microcycle
    let microcycle = microcycles.find(
      (microcycle) => microcycle.value === value
    );
    // set the selected microcycle
    setSelectedMicrocycle(microcycle.id);
  };

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
  };

  const handleDayChange = (value, button) => {
    if (selectedDay >= 0 && selectedDay <= 6) {
      setSelectedDay(selectedDay + value);
      if (value === -1 && selectedDay === 0) {
        setSelectedDay(0);
      } else if (value === 1 && selectedDay === 4) {
        setSelectedDay(4);
      }
    }
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND + `training/microcycle-list`, {headers})
      .then((response) => {
        let microcycles_settle = [];
        for (let i = 0; i < response.data.length; i++) {
          microcycles_settle.push({
            label: `${response.data[i].start_date}-${response.data[i].until_date}`,
            value: response.data[i].id,
            id: response.data[i].id,
          });
        }
        setMicrocycles(microcycles_settle);
        setSelectedMicrocycle(microcycles_settle[0].id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedMicrocycle !== null) {
      if (page == "total") {
        axios
          .get(process.env.REACT_APP_BACKEND + `training/uc-total-cycles-data/${selectedMicrocycle}`,{ headers })
          .then((response) => {
            setSessions(response.data.sessions);
            setStatistics(response.data.statistics);
            

            //got throu each session and add the players from every session to the players array, no duplicates
            let players = [];
            response.data.sessions.map((session) => {
              session.playersUCTOTAlData.map((player) => {
                //compare the ids of the players in the players array with the player id from the session
                let playerIndex = players.findIndex(
                  (p) => p.player_id === player.player_id
                );
                if (playerIndex === -1) {
                  //if the player is not in the array, add it
                  players.push(player);
                }
              });
            });
              
            setPlayers(players);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else if (page == "gym") {
        if (selectedFilter === "0") {
          axios
            .get(process.env.REACT_APP_BACKEND + `training/ucgym-sessions-cycle-data/${selectedMicrocycle}`, { headers })
            .then((response) => {
            setSessions(response.data.sessions);
            //got throu each session and add the players from every session to the players array, no duplicates
            let players = [];
              
            response.data.sessions.map((session) => {
              session.playersUCGymData.map((player) => {
                    
                //compare the ids of the players in the players array with the player id from the session
                let playerIndex = players.findIndex(
                  (p) => p.player_id === player.player_id
                );
                if (playerIndex === -1) {
                  //if the player is not in the array, add it
                  players.push(player);
                }
              });
            });
            setPlayers(players);
            });
        } else {
          axios
            .get(process.env.REACT_APP_BACKEND + `training/ucgym-cycle-statisctics/${selectedMicrocycle}`, { headers })
            .then((response) => {
            setStatistics(response.data.statistics);
            //gt through each session and add the players from every session to the players array, no duplicates
            let players = [];
            response.data.players.map((player) => {
              players.push(player);
            });
            setPlayers(players);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      }
      makeFilters();
    }
  }, [selectedMicrocycle, page]);

    const formatTime = (time) => {
      if (time === "-") return time;

      // from seconds to hh:mm:ss
      let hours = Math.floor(time / 3600);
      let minutes = Math.floor((time - hours * 3600) / 60);
      let seconds = Math.floor(time - hours * 3600 - minutes * 60);

      return `${hours}:${minutes}:${seconds}`;
    };

    const changeColor = (value) => {
      if (value >= 1 && value <= 1.3) {
        return "green";
      } else if (value < 1) {
        return "yellow";
      } else if (value > 1.3) {
        return "red";
      }
    }

    const placeBackgroundImages = () => {
      const bckLogo1 = document.getElementById("webapp-create-team-bcklogo1");
      const bckLogo2 = document.getElementById("webapp-create-team-bcklogo2");
      const bckLogo1Height = bckLogo1.offsetHeight;
      bckLogo1.style.top = `calc(60% - ${bckLogo1Height}px)`;
      bckLogo2.style.top = `0%`;
      const bckLogo1Width = bckLogo1.offsetWidth;
      bckLogo1.style.left = `calc(60% - ${bckLogo1Width}px)`;
      bckLogo2.style.left = `75%`;
  }

  useEffect(() => {
      placeBackgroundImages();
      window.addEventListener('resize', placeBackgroundImages);
      return () => {
          window.removeEventListener('resize', placeBackgroundImages);
      }
  }, [])

  // returns the desired filters
  const makeFilters = () => {
    let filter = [{name:"Position", content:[]},
                  {name:"Name", content:[]},
                  {name:"MC", content:[]}];
    players.map((player) =>{
      filter[1].content.push({value: player.player_name, label: player.player_name});
      if (!filter[0].content.some(item => item.value === player.player_position)) { // avoid position repetition
        filter[0].content.push({ value: player.player_position, label: player.player_position });
      }
    })
    
    microcycles.map((mc, index) =>{
      filter[2].content.push({value: mc.value, label: "MC"+(index+1)})
    })

    filter[0].content.sort((a, b) => (a.label < b.label ? -1 : 1)); // sort by label pos
    filter[1].content.sort((a, b) => (a.label < b.label ? -1 : 1)); // sort by label name

    SetFilters(filter);
    setDisplayedFilters(filter);
    setNumberDisplayedNames(0);
  }

  const handleFilter = (filterName, value) => {
    let index = selectedFilters[filterName].findIndex((element) => element === value);
    
    if(index == -1){
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: [...prevFilters[filterName], value] ,
      }));
    }else{
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: selectedFilters[filterName].filter((element) => element!==value ),
      }));
    }
  };

  useEffect(() => {
    const cenas = {
      labels: [],
      // line define o ponto médio ou a relação entre dois fatores
      // bar o valor de cada fator
      // CA/CC e Relação
      graph1: { line: [], bar1: [], bar2: [] },
      // Carga CA/CC e variação percentual
      graph2: { line: [], bar1: [], bar2: [] },
      // Unidade de carga e I.E.
      graph3: { line: [], bar1: [] }
    }


    if (numberDisplayedNames!==selectedFilters.Name.length){
      // 1 = Name 0 = Pos 2 = MC
      setNumberDisplayedNames(selectedFilters.Name.length);
      let newDisplay = displayedFilters.map((item) => ({
        ...item,
        content: [...item.content]
      }));

      const restoreFilters = [...filters[0].content];
      if (selectedFilters.Name.length === 0) newDisplay[0].content = restoreFilters;
      setDisplayedFilters(newDisplay)
    }

    if (numberDisplayedPos!=selectedFilters.Position.length){
      setNumberDisplayedPos(selectedFilters.Position.length);
      let newDisplay = displayedFilters.map((item) => ({
        ...item,
        content: [...item.content]
      }));

      const restoreFilters = [...filters[1].content];
      if (selectedFilters.Position.length === 0) {
        newDisplay[1].content = restoreFilters;      
      }
      else {  // just display the names that have the selected positions
        let added_players = players
          .filter((item) => selectedFilters.Position.includes(item.player_position))
          .map((item) => ({
            value: item.player_name,
            label: item.player_name
        }));
        newDisplay[1].content = [] //maintain this to clean the players
        setDisplayedFilters(newDisplay);
        newDisplay[1].content = added_players.sort((a, b) => (a.label < b.label ? -1 : 1)); // sort by label;
      }

      setDisplayedFilters(newDisplay);

    }

    const checkName = selectedFilters.Name.length!==0;
    const checkPosition = selectedFilters.Position.length!==0;

    // will only request for the MC selected an not already requested - its stored in microcycleData
    selectedFilters.MC.map((mc)=>{
      let aux = new Set();
      const mcName = filters[2].content.find((item) => item.value === Number(mc)).label
      if (microcycleData[mc] === undefined){
        axios.get(process.env.REACT_APP_BACKEND + `training/uc-total-graph-data/${mc}`, { headers })
          .then((response) => {
            let newMCFilters = {...microcycleData};
            filterPlayers(response.data.statistics, checkName, checkPosition, mcName).forEach(item => aux.add(item)); // append the new players to the set
            newMCFilters[mc] = response.data.statistics;
            setMicrocycleData(newMCFilters);

            // filter players and update cenas - leave this here so it waits for the API response
            const uniquePlayers = Array.from(aux);
            uniquePlayers.forEach((p)=>{
              cenas.labels.push(`${p.id} ${p.player_name} ${t(p.player_position)}`);
              cenas.graph1.line.push(p.stats.uc_cl_relation);
              cenas.graph1.bar1.push(p.stats.uc_total_sum);
              cenas.graph1.bar2.push(p.stats.ucgym_chronic_load);
              
              cenas.graph2.line.push(p.stats.uc_cl_percentual);
              cenas.graph2.bar1.push(p.stats.uc_total_sum);
              cenas.graph2.bar2.push(p.stats.ucgym_chronic_load);
      
              cenas.graph3.line.push(p.stats.uc_total_sum);
              cenas.graph3.bar1.push(microcycleData[mc].ie_avg);
            })
            
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
      else {
        // filter players and update cenas
        filterPlayers(microcycleData[mc], checkName, checkPosition, mcName).forEach(item => aux.add(item)); // append the new players to the set
        const uniquePlayers = Array.from(aux);
        uniquePlayers.forEach((p)=>{
          cenas.labels.push(`${p.id} ${p.player_name} ${t(p.player_position)}`);
          cenas.graph1.line.push(p.stats.uc_cl_relation);
          cenas.graph1.bar1.push(p.stats.uc_total_sum);
          cenas.graph1.bar2.push(p.stats.ucgym_chronic_load);
          
          cenas.graph2.line.push(p.stats.uc_cl_percentual);
          cenas.graph2.bar1.push(p.stats.uc_total_sum);
          cenas.graph2.bar2.push(p.stats.ucgym_chronic_load);
  
          cenas.graph3.line.push(p.stats.uc_total_sum);
          cenas.graph3.bar1.push(microcycleData[mc].ie_avg);
        })
      }
     
    })
    // display graph
    setGraphData(cenas);
    
  }, [selectedFilters])

  const filterPlayers = (mc, checkName, checkPos, mc_id) =>{
    const playersData = mc.players;
    let aux = []; // store the desired players from this microcylce
    playersData.map((p) =>{
      p["id"] = mc_id;
      // gotta filter by position first
      if (checkPos && selectedFilters.Position.includes(p.player_position)){
        if (checkName){
          if (selectedFilters.Name.includes(p.player_name)) aux.push(p); // insert the desired players from some position
        }
        else aux.push(p); // just insert all with the desired position

        if (selectedFilters.Name.includes(p.player_name)) {
          aux.push(p);
        }
      }
      else{ // not position given
        if (checkName && selectedFilters.Name.includes(p.player_name)) aux.push(p);
      }
    })
    return aux;
  }
  
  // change page but also resets the data for stats page
  const changePage = (pageName) =>{
    setPage(pageName);
    if (pageName === "stats"){
      setSelectedFilters({
        "MC" : [],
        "Position" : [],
        "Name":[]
      });
      setNumberDisplayedNames(0);
      setNumberDisplayedPos(0);
    }
  }


  return (
    <div className="webapp-uc">
      <Sidebar selectedOption="load-unit" />
      <div className="webapp-uc-content">
        <div className="webapp-create-team-background-images">
            <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo1" />
            <img src={BckLogo} alt="Logo" className="webapp-create-team-bcklogo" id="webapp-create-team-bcklogo2" />
        </div>
        <h1 className="webapp-uc-content-title">{t("Load unit")}</h1>
        <div className="webapp-uc-content-header">
          <div className="webapp-uc-content-header-buttons">
            <button
              className={`webapp-uc-content-header-button ${
                page == "total" && "webapp-uc-content-header-button-selected"
              }`}
              onClick={() => changePage("total")}
            >
              <h4>{t("Total")}</h4>
            </button>
            <button
              className={`webapp-uc-content-header-button ${
                page == "gym" && "webapp-uc-content-header-button-selected"
              }`}
              onClick={() => changePage("gym")}
            >
              <h4>{t("Gym")}</h4>
            </button>
            <button
              className={`webapp-uc-content-header-button ${
                page == "stats" && "webapp-uc-content-header-button-selected"
              }`}
              onClick={() => changePage("stats")}
            >
              <h4>{t("Statistics")}</h4>
            </button>
          </div>
          {page != "stats" && <div className="webapp-uc-content-header-filters">
            <FilterBy
              options={microcycles}
              onChange={handleMicrocycleChange}
            />
            <FilterBy options={filterOptions} onChange={handleFilterChange} />
          </div>}
        </div>
        {page == "total" ? (
          // UC TOTAL CONTENT ############################
          <div className="webapp-uc-content-body">
            <div className="webapp-uc-content-body-titles">
              {selectedFilter === "0" ? (
                <>
                  {sessions.map((session, index) => {
                    if (index < selectedDay + 3 && index >= selectedDay) {
                      if (index === selectedDay) {
                        return (
                          <div key={index} className="webapp-uc-content-body-titles-content-day">
                            <button
                              className="webapp-uc-content-body-arrow"
                              onClick={() => handleDayChange(-1)}
                            >
                              &lt;
                            </button>
                            <div className="webapp-uc-content-body-titles-content-day-info">
                              <h4>{t(session?.weekdays)}</h4>
                              <p>{session?.date}</p>
                            </div>
                            <button className="webapp-uc-content-body-arrow-invisible">
                              &lt;
                            </button>
                          </div>
                        );
                      } else if (index === selectedDay + 2) {
                        return (
                          <div key={index} className="webapp-uc-content-body-titles-content-day">
                            <button className="webapp-uc-content-body-arrow-invisible">
                              &lt;
                            </button>
                            <div className="webapp-uc-content-body-titles-content-day-info">
                              <h4>{t(session?.weekdays)}</h4>
                              <p>{session?.date}</p>
                            </div>
                            <button
                              className="webapp-uc-content-body-arrow"
                              onClick={() => handleDayChange(+1)}
                            >
                              &gt;
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div key={index} className="webapp-uc-content-body-titles-content-day">
                            <button className="webapp-uc-content-body-arrow-invisible">
                              &lt;
                            </button>
                            <div className="webapp-uc-content-body-titles-content-day-info">
                              <h4>{t(session?.weekdays)}</h4>
                              <p>{session?.date}</p>
                            </div>
                            <button className="webapp-uc-content-body-arrow-invisible">
                              &lt;
                            </button>
                          </div>
                        );
                      }
                    }
                  })}
                </>
              ) : (
                <div className="webapp-uc-content-body-titles-total">
                  <h4>{t("Total Microcycle")}</h4>
                  <p>
                    {" "}
                    {sessions[0].date}-{sessions[sessions.length - 1].date}{" "}
                  </p>
                </div>
              )}
            </div>
            {players.length > 0 ? (
              <div className="webapp-uc-content-body-tables">
                <div className="webapp-uc-content-body-table-first">
                  <h2 className="webapp-uc-content-body-table-name">
                    {t("Name")}
                  </h2>
                  {players?.map((player) => {
                    return (
                      <div className="webapp-uc-content-body-table-first-value">
                        <p>{player.player_name}</p>
                        <p>{player.player_position}</p>
                      </div>
                    );
                  })}
                  <h3 className="webapp-uc-content-body-table-first-value">
                    {t("Z-Average")}
                  </h3>
                </div>
                {selectedFilter === "0" ? (
                  <>
                    {sessions.map((session, index) => {
                      if (index < selectedDay + 3 && index >= selectedDay) {
                        return (
                          <div className="webapp-uc-content-body-table-columns">
                            <div className="webapp-uc-content-body-table-columns-indentifiers">
                              <h2 className="webapp-uc-content-body-table-columns-name">
                                T
                              </h2>{" "}
                              {/* T = Time, equal in all lan*/}
                              <h2 className="webapp-uc-content-body-table-columns-name">
                                {t("SPE")}
                              </h2>
                              <h2 className="webapp-uc-content-body-table-columns-name">
                                {t("SI")}
                              </h2>
                              <h2 className="webapp-uc-content-body-table-columns-name">
                                {t("LU")}
                              </h2>
                            </div>
                            <div className="webapp-ucstats-bottom-columns-values-list">
                              {session?.playersUCTOTAlData?.map((player) => {
                                //match the player with the player in the players array
                                return (
                                  <div className="webapp-ucstats-bottom-columns-values">
                                    <p className="webapp-uc-content-body-table-columns-value">
                                      {player.uctotal_time
                                        ? formatTime(player.uctotal_time)
                                        : "-"}
                                    </p>
                                    <p className="webapp-uc-content-body-table-columns-value">
                                      {player.uctotal_pse
                                        ? player.uctotal_pse.toFixed(2)
                                        : "-"}
                                    </p>
                                    <p className="webapp-uc-content-body-table-columns-value">
                                      {player.uctotal_ie
                                        ? player.uctotal_ie.toFixed(2)
                                        : "-"}
                                    </p>
                                    <p className="webapp-uc-content-body-table-columns-value">
                                      {player.uctotal_uc
                                        ? player.uctotal_uc.toFixed(2)
                                        : "-"}
                                    </p>
                                  </div>
                                );
                              })}
                              <div className="webapp-ucstats-bottom-columns-values">
                                {session?.zpromedio && (
                                  <>
                                    <p className="webapp-uc-content-body-table-columns-value">
                                      {session.zpromedio.time_avg
                                        ? formatTime(session.zpromedio.time_avg)
                                        : "-"}
                                    </p>
                                    <p className="webapp-uc-content-body-table-columns-value">
                                      {session.zpromedio.pse_avg
                                        ? session.zpromedio.pse_avg
                                        : "-"}
                                    </p>
                                    <p className="webapp-uc-content-body-table-columns-value">
                                      {" "}
                                      {session.zpromedio.ie_avg
                                        ? session.zpromedio.ie_avg
                                        : "-"}{" "}
                                    </p>
                                    <p className="webapp-uc-content-body-table-columns-value">
                                      {session.zpromedio.uc_avg
                                        ? session.zpromedio.uc_avg
                                        : "-"}
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </>
                ) : (
                  <div className="webapp-uc-content-body-table-columns webapp-uc-content-body-table-column">
                    <div className="webapp-uc-content-body-table-columns-indentifiers">
                      <h4 className="webapp-uc-content-body-table-columns-name">
                        {t("SI Total")}
                      </h4>
                      <h4 className="webapp-uc-content-body-table-columns-name">
                        {t("Load")} <br /> {t("Total")} MC
                      </h4>{" "}
                      {/* MC is equal in all lan */}
                      <h4 className="webapp-uc-content-body-table-columns-name">
                        {t("Average Load")}
                      </h4>
                      <h4 className="webapp-uc-content-body-table-columns-name">
                        {t("SD")}
                      </h4>
                      <h4 className="webapp-uc-content-body-table-columns-name">
                        {t("Monotony")}
                      </h4>
                      <h4 className="webapp-uc-content-body-table-columns-name">
                        {t("ACWR")}
                      </h4>
                      <h4 className="webapp-uc-content-body-table-columns-name">
                        {t("Difference (%)")}<br />  {t("ACWR")}
                      </h4>
                      <h4 className="webapp-uc-content-body-table-columns-name">
                        {t("Ratio")} <br /> {t("ACWR")}
                      </h4>
                    </div>
                    <div className="webapp-ucstats-bottom-columns-values-list">
                      {statistics?.players?.map((player, index) => {
                        let stats = player.stats;
                        return (
                          <div className="webapp-ucstats-bottom-columns-values">
                            <p className="webapp-uc-content-body-table-columns-value">
                              {stats?.ie_total_sum ? stats?.ie_total_sum : "0"}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {stats?.uc_total_sum ? stats?.uc_total_sum : "0"}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {stats?.mean ? stats?.mean : "0"}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {stats?.std ? stats?.std : "0"}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {stats?.monotony_index 
                                ? stats?.monotony_index
                                : "0"}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {stats?.chronic_load ? stats?.chronic_load : "0"}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {stats?.uc_cl_percentual
                                ? stats?.uc_cl_percentual
                                : "0"}
                              %
                            </p>
                            <p className={`webapp-uc-content-body-table-columns-value ${changeColor(stats?.uc_cl_relation)}`}>
                              {stats?.uc_cl_relation
                                ? stats?.uc_cl_relation
                                : "0"}
                            </p>
                          </div>
                        );
                      })}
                      <div className="webapp-ucstats-bottom-columns-values">
                        {statistics && (
                          <>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {statistics?.total_cycle_zpromedio?.ie_total_sum
                                ? statistics?.total_cycle_zpromedio
                                    ?.ie_total_sum
                                : 0}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {statistics?.total_cycle_zpromedio?.ucgym_total
                                ? statistics?.total_cycle_zpromedio?.ucgym_total
                                : 0}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {" "}
                              {statistics?.total_cycle_zpromedio?.mean
                                ? statistics?.total_cycle_zpromedio?.mean
                                : 0}{" "}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {statistics?.total_cycle_zpromedio?.std
                                ? statistics?.total_cycle_zpromedio?.std
                                : 0}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {statistics?.total_cycle_zpromedio?.monotony_index
                                ? statistics?.total_cycle_zpromedio
                                    ?.monotony_index
                                : 0}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {statistics?.total_cycle_zpromedio?.chronic_load
                                ? statistics?.total_cycle_zpromedio
                                    ?.chronic_load
                                : 0}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {" "}
                              {statistics?.total_cycle_zpromedio
                                ?.uc_cl_percentual
                                ? statistics?.total_cycle_zpromedio
                                    ?.uc_cl_percentual
                                : 0}
                              %{" "}
                            </p>
                            <p className={`webapp-uc-content-body-table-columns-value ${changeColor(statistics?.total_cycle_zpromedio?.uc_cl_relation)}`}>
                              {statistics?.total_cycle_zpromedio?.uc_cl_relation
                                ? statistics?.total_cycle_zpromedio
                                    ?.uc_cl_relation
                                : 0}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="webapp-uc-total-no-data">
                <h2>{t("No player data to be displayed")}</h2>
              </div>
            )}
          </div>
        ) : page == "gym" ? (
          // UC GYM CONTENT ############################
          <div className="webapp-uc-content-body">
            <div className="webapp-uc-content-body-titles">
              {selectedFilter === "0" ? (
                <>
                  {sessions.map((session, index) => {
                    if (index < selectedDay + 3 && index >= selectedDay) {
                      if (index === selectedDay) {
                        return (
                          <div className="webapp-uc-content-body-titles-content-day" key={index}>
                            <button
                              className="webapp-uc-content-body-arrow"
                              onClick={() => handleDayChange(-1)}
                            >
                              &lt;
                            </button>
                            <div className="webapp-uc-content-body-titles-content-day-info">
                              <h4>{t(session?.weekdays)}</h4>
                              <p>{session?.date}</p>
                            </div>
                            <button className="webapp-uc-content-body-arrow-invisible">
                              &lt;
                            </button>
                          </div>
                        );
                      } else if (index === selectedDay + 2) {
                        return (
                          <div className="webapp-uc-content-body-titles-content-day" key={index}>
                            <button className="webapp-uc-content-body-arrow-invisible">
                              &lt;
                            </button>
                            <div className="webapp-uc-content-body-titles-content-day-info">
                              <h4>{t(session?.weekdays)}</h4>
                              <p>{session?.date}</p>
                            </div>
                            <button
                              className="webapp-uc-content-body-arrow"
                              onClick={() => handleDayChange(+1)}
                            >
                              &gt;
                            </button>
                          </div>
                        );
                      } else {
                        return (
                          <div className="webapp-uc-content-body-titles-content-day" key={index}>
                            <button className="webapp-uc-content-body-arrow-invisible">
                              &lt;
                            </button>
                            <div className="webapp-uc-content-body-titles-content-day-info">
                              <h4>{t(session?.weekdays)}</h4>
                              <p>{session?.date}</p>
                            </div>
                            <button className="webapp-uc-content-body-arrow-invisible">
                              &lt;
                            </button>
                          </div>
                        );
                      }
                    }
                  })}
                </>
              ) : (
                <div className="webapp-uc-content-body-titles-total">
                  <h4>{t("Total Microciclo")} </h4>
                  <p>
                    {" "}
                    {sessions[0].date}-{sessions[sessions.length - 1].date}{" "}
                  </p>
                </div>
              )}
            </div>
            {players.length > 0 ? (
              <div className="webapp-uc-content-body-tables">
                <div className="webapp-uc-content-body-table-first">
                  <h2 className="webapp-uc-content-body-table-name">
                    {t("Name")}
                  </h2>
                  {players?.map((player) => {
                    return (
                      <div className="webapp-uc-content-body-table-first-value">
                        <p>{player.player_name}</p>
                        <p>{player.player_position}</p>
                      </div>
                    );
                  })}
                  <h3 className="webapp-uc-content-body-table-first-value">
                    {t("Z-Average")}
                  </h3>
                </div>
                {selectedFilter === "0" ? (
                  <>
                    {sessions.map((session, index) => {
                      if (index < selectedDay + 3 && index >= selectedDay) {
                        return (
                          <div className="webapp-uc-content-body-table-columns">
                            <div className="webapp-uc-content-body-table-columns-indentifiers">
                              <h2 className="webapp-uc-content-body-table-columns-name">
                                T
                              </h2>
                              <h2 className="webapp-uc-content-body-table-columns-name">
                                {t("SPE")}
                              </h2>
                              <h2 className="webapp-uc-content-body-table-columns-name">
                                {t("LU")}
                              </h2>
                            </div>
                            <div className="webapp-ucstats-bottom-columns-values-list">
                              {session?.playersUCGymData?.map((player) => {
                                //match the player with the player in the players array
                                return (
                                  <div className="webapp-ucstats-bottom-columns-values">
                                    <p className="webapp-uc-content-body-table-columns-value">
                                      {player.ucgym_time
                                        ? formatTime(player.ucgym_time)
                                        : "-"}
                                    </p>
                                    <p className="webapp-uc-content-body-table-columns-value">
                                      {player.ucgym_pse
                                        ? player.ucgym_pse
                                        : "-"}
                                    </p>
                                    <p className="webapp-uc-content-body-table-columns-value">
                                      {player.ucgym_uc
                                        ? player.ucgym_uc
                                        : "-"}
                                    </p>
                                  </div>
                                );
                              })}
                              <div className="webapp-ucstats-bottom-columns-values">
                                {page == "gym" && (
                                  <>
                                    <p className="webapp-uc-content-body-table-columns-value">
                                      {session.zpromedio.time_avg
                                        ? formatTime(session.zpromedio.time_avg)
                                        : "-"}
                                    </p>
                                    <p className="webapp-uc-content-body-table-columns-value">
                                      {session.zpromedio.pse_avg
                                        ? session.zpromedio.pse_avg
                                        : "-"}
                                    </p>
                                    <p className="webapp-uc-content-body-table-columns-value">
                                      {session.zpromedio.uc_avg
                                        ? session.zpromedio.uc_avg
                                        : "-"}
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </>
                ) : (
                  <div className="webapp-uc-content-body-table-columns webapp-uc-content-body-table-column">
                    <div className="webapp-uc-content-body-table-columns-indentifiers">
                      <h4 className="webapp-uc-content-body-table-columns-name">
                        {t("Load")} <br /> {t("Total")} MC
                      </h4>
                      <h4 className="webapp-uc-content-body-table-columns-name">
                        {t("Average Load")}
                      </h4>
                      <h4 className="webapp-uc-content-body-table-columns-name">
                        {t("SD")}
                      </h4>
                      <h4 className="webapp-uc-content-body-table-columns-name">
                        {t("Monotony")}
                      </h4>
                      <h4 className="webapp-uc-content-body-table-columns-name">
                        {t("Acute: Chronic Load")}
                      </h4>
                      <h4 className="webapp-uc-content-body-table-columns-name">
                        {t("Difference (%)")}<br /> {t("ACWR")}
                      </h4>
                      <h4 className="webapp-uc-content-body-table-columns-name">
                        {t("Ratio")} <br /> {t("ACWR")}
                      </h4>
                    </div>
                    <div className="webapp-ucstats-bottom-columns-values-list">
                      {statistics?.players?.map((player, index) => {
                        return (
                          <div className="webapp-ucstats-bottom-columns-values">
                            <p className="webapp-uc-content-body-table-columns-value">
                              {player.ucgym_total_sum
                                ? player.ucgym_total_sum
                                : "0"}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {player.ucgym_mean ? player.ucgym_mean : "0"}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {player.ucgym_std ? player.ucgym_std : "0"}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {player.ucgym_monotomy_index
                                ? player.ucgym_monotomy_index
                                : "0"}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {player.ucgym_chronic_load
                                ? player.ucgym_chronic_load
                                : "0"}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {player.uc_cl_percentual
                                ? player.uc_cl_percentual
                                : "0"}
                              %
                            </p>
                            <p className={`webapp-uc-content-body-table-columns-value ${changeColor(player.uc_cl_relation ? player.uc_cl_relation : 0)}`}>
                              {player.uc_cl_relation
                                ? player.uc_cl_relation
                                : "0"}
                            </p>
                          </div>
                        );
                      })}

                      <div className="webapp-ucstats-bottom-columns-values">
                        {statistics && (
                          <>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {
                                statistics?.total_cycle_zpromedio?.ucgym_total_sum
                              }
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {" "}
                              {
                                statistics?.total_cycle_zpromedio?.ucgym_mean
                              }{" "}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {statistics?.total_cycle_zpromedio?.ucgym_std}
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {
                                statistics?.total_cycle_zpromedio
                                  ?.ucgym_monotony_index
                              }
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {
                                statistics?.total_cycle_zpromedio
                                  ?.ucgym_chronic_load
                              }
                            </p>
                            <p className="webapp-uc-content-body-table-columns-value">
                              {" "}
                              {
                                statistics?.total_cycle_zpromedio
                                  ?.uc_cl_percentual
                              }
                              %{" "}
                            </p>
                            <p className={`webapp-uc-content-body-table-columns-value ${changeColor(statistics?.total_cycle_zpromedio?.uc_cl_relation)}`}>
                              {
                                statistics?.total_cycle_zpromedio
                                  ?.uc_cl_relation
                              }
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="webapp-uc-total-no-data">
                <h2>{t("No player data to be displayed")}</h2>
              </div>
            )}
          </div>
        ) : (
          <GraphsUC data={graphData} filters={displayedFilters} handle={handleFilter} type={"checkbox"}/>
        )}
      </div>
    </div>
  );
};

export default UCpage;
