import { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Axios from 'axios';
import axios from "axios";
import { useTranslation } from "react-i18next";

const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({ children }) => {
    const { t, i18n } = useTranslation();
    const [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    const [coachTeam, setCoachTeam] = useState(true)
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()

    const loginUser = async (e) => {
        e.preventDefault()
        try{
            const response = (await Axios.post(process.env.REACT_APP_BACKEND + 'api/token/',{
                username: e.target.username.value,
                password: e.target.password.value
            },
            {headers: {
                'Content-Type': 'application/json'
            }
            }))

            const data = await response.data
            
            if (response.status === 200) {
                i18n.changeLanguage(jwtDecode(data.access).language)
                setAuthTokens(data)
                localStorage.setItem('authTokens', JSON.stringify(data))

                if (jwtDecode(data.access).role === 'COACH'){
                    try {
                        const headers = {
                            Authorization: `Bearer ${data.access}`,
                        };

                        const response = (await Axios.get(process.env.REACT_APP_BACKEND + 'users/team', { headers }))

                        if (response.status === 200) {
                            setCoachTeam(true)
                            navigate("/webapp/trainings")
                        }
                    }catch(error){
                        if (error.response.status === 404) {
                            setCoachTeam(false)
                            navigate('/webapp/createteam')
                        } else {
                            console.error(error);
                        }
                    }
                }else if (jwtDecode(data.access).role === 'PLAYER') {
                    navigate("/webapp/player/forms")
                } else {
                    logoutUser();
                }
            }
        }catch(error){
            return false
        }
    }


    const logoutUser = () => {
        setAuthTokens(null)
        localStorage.removeItem('authTokens')
        localStorage.removeItem('coachData')
        navigate("/webapp/login")
    }

    const checkTeam = async () => {
        const headers = {
            Authorization: `Bearer ${authTokens.access}`,
        };

        axios.get(process.env.REACT_APP_BACKEND + 'users/team', { headers })
        .then(response => {
            setCoachTeam(true)
        })
        .catch(error => {
            console.error(error)
            if (error.response.status === 404)
                setCoachTeam(false)
        });
    }

    const createTeamRedirect = () => {
      setCoachTeam(true);
      navigate('/webapp/trainings');
    }

    const updateToken = async() => {
        if (!authTokens) {
            if (loading) {
                setLoading(false)
            }
            return
        }else{
            try{
                const response = await fetch(process.env.REACT_APP_BACKEND + 'api/token/refresh/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({'refresh': authTokens?.refresh})
                })
                const data = await response.json()

                if (response.status === 200) {
                    checkTeam();
                    setAuthTokens(data)
                    localStorage.setItem('authTokens', JSON.stringify(data))
                }
                if (loading) {
                    setLoading(false)
                }
            }
            catch(error) {
                if (loading) {
                    setLoading(false)
                }
            }
        }
    }


    useEffect(() => {
        const fetchToken = async () => {
            if (loading) {
                await updateToken();
                setLoading(false);
            }
        };

        fetchToken();

        const time = 1000 * 4 * 60;

        const intervalId = setInterval(async () => {
            if (authTokens && !loading) {
                await updateToken();
            }
        }, time);

        return () => clearInterval(intervalId);
    }, [authTokens, loading]);


    const contextData = {
        loginUser:loginUser,
        authTokens:authTokens,
        logoutUser:logoutUser,
        updateToken:updateToken,
        coachTeam:coachTeam,
        createTeamRedirect: createTeamRedirect,
    }


    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    )
}
